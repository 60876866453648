.Section1 {
  .ydmslogo {
    width: 10%;
    position: absolute;
    top: 3%;
    left: 10%;
    z-index: 9999;
  }
  .carousel-root {
    margin: -10px;
    color: #ffcc00;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 3px 3px 2px #626262;
  }
  .carousel-image {
    filter: brightness(1);
    height: 100vh;
    width: 100vw;
    z-index: 0;
  }
  .carousel-text {
    margin: -10px;
    color: #FFCC00;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 3px 3px 2px #626262;

    position: absolute;
    top: 20%;
    left: 10%;
    max-width: 30%;
    text-align: left;
    font-size: 3vw;
    // line-height:6vw;
    z-index: 9999;
  }
  .carousel-text2 {
    position: absolute;
    color: #ffffff;
    font-family: "ProximaNova", Sans-serif;
    text-shadow: 2px 2px 2px #626262;
    font-size: 1.5vw;
    font-weight: 400;
    position: absolute;
    top: 45%;
    left: 10%;
    max-width: 70%;
    text-align: left;
    text-transform: capitalize;
    z-index: 20;
  }
  .button-red-signup {
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color: #bd2944;
    border-color: #0b6091;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: 4%;
    right: 10%;
    z-index: 9999;
    margin-right: 20px;
  }
  .button-yellow-login {
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color: #ffcc00;
    border-color: #0b6091;
    color: black;
    display: inline-block;
    position: absolute;
    top: 4%;
    right: 2%;
    z-index: 9999;
    margin-right:25px!important;
  }
  .overlay {
    background: radial-gradient(
      circle at 80% 80%,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.3),
      #ffffff
    );
    opacity: 0.6;
    mix-blend-mode: normal;
    height: 100vh;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 0;
  }
  .menu-bar-button-icon{
    display: none;
  }
  .section-footer-subheader{
    color:"white"
  }
  @media only screen and (max-width: 990px) {
    .menu-bar-button-icon{
      display:initial;
      margin-left:10px;
      margin-top:10px;
      position: absolute;
      left: 10px;
      z-index: 9;
    }
    .ydmslogo {
      width: 30%;
      top: 1%;
      left: 20%;
    }
    .carousel-image {
      width: 100vh;
      height: 50vh;
    }
    .carousel-text {
      font-size: 5vw;
      text-shadow: 1px 1px 1px #626262;
    }
    .carousel-text2 {
      font-size: 3vw;
      text-shadow: 1px 1px 1px #626262;
    }
    .button-yellow-login {
      font-size: 2.3vw;
      margin-right:5px!important;
    }
    .button-red-signup {
      font-size: 2.3vw;
      margin-right:55px!important;
    }
    .overlay {
      width: 100vh;
      height: 50vh;
    }
  }
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
