@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
    /* padding-top: 50px!important; */
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}


.service-section-main-content-title{
    color: #0767C8;
    font-family: "ProximaNova", Sans-serif;
    font-size: 3.125em;
    font-weight: 600;
    line-height: 1.15em;
    letter-spacing: -2.8px;
    text-shadow: 0px 3px 3px rgb(152 152 152 / 93%);
}
.service-page-sub-header{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 25px;
    font-weight: 600;
    padding: 0;
    line-height: 1;
    margin-bottom: 20px;
}
.service-section-description-container>.row>div>p{
    margin-top: 0;
    margin-bottom: .9rem;
    color: #7a7a7a;
    font-family: "ProximaNova", Sans-serif;
    font-size: 17px;

}
.service-section-description-container>.row>div:first-child{
    padding-right: 90px;
}
.service-button-join-event{
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 0px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #FB6700;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #ffffff;
    border-radius: 7px 7px 7px 7px;
    padding: 17px 50px 17px 50px;
    margin-top: 1em;
}
.service-event-list-items{
    list-style-type: none;
    padding: 0px;    
}
.service-event-list-item{
    display: flex;
    margin-bottom: 1em;
}
.service-event-list-item svg{
    color: #FB6700;
    font-size: 1em;   
    margin-right: 0.8em; 
    margin-top: 0.1em;
}
.service-event-list-item a{
    color: #54595F;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    line-height: 1.2;
}
.service-event-list-item a:hover{
    color: #c36;
}

.service-section-ifyouare .row>div>div{
    display: flex;
    align-items: center;   
    padding: 40px 40px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #efefef;
}
.service-section-roles .row>div>div{
    display: flex;
    align-items: center;   
    padding: 40px 40px;
}
.service-section-ifyouare-card.icon{
    width: 62px;
    height: 62px;
    background: #ffffff;
    padding: 12px;
    fill: #FB6700;
    color: #FB6700;
    border-color: #FB6700;
    vertical-align: middle;
    border-width: 1px 1px 1px 1px;
    border: 1px solid;
    margin-right: 2em;   
    transition-property: transform;
    transition-duration: .3s;
}
.service-section-ifyouare-card.icon:hover , .service-section-roles-card.icon:hover{
    transform: translateY(-10px);
}
.service-section-ifyouare-card.text , .service-section-roles-card.text{
    color: #7a7a7a;
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;
}

.service-section-roles-card.icon{
    width: 62px;
    height: 62px;
    background-color: #FFCC00;
    padding: 15px;
    fill: #ffffff;
    color: #ffffff;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 2em;   
    transition-property: transform;
    transition-duration: .3s;
}
.service-section-testimonial-card-container{
    padding-bottom: 80px;

}

.service-section-testimonial-card-container-text{
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5;
    color: #7a7a7a;
    margin-bottom: 30px;
    margin-top: 30px;
}

.service-section-testimonial-card-container img{
    width: 50px !important;
    height: 50px;
    border-radius: 999px;
    object-fit: cover;
}
.service-section-testimonial-card-container-name{
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    color: #7a7a7a;
}
.service-section-testimonial-card-container-title{
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    color: #6ec1e4;
}




.service-carousel-container  .ant-carousel img{
    width: 100%;
}
.service-carousel-container  .ant-carousel .slick-prev,
.service-carousel-container  .ant-carousel .slick-next {
    color: #000000;
    font-size: unset;
}

.service-carousel-container  .ant-carousel .slick-prev:hover,
.service-carousel-container  .ant-carousel .slick-next:hover {
    color: #818181;
    font-size: unset;
}



/* Mobile */
@media only screen and (max-width: 1025px) {
.service-section-description-container>.row>div:first-child{
    padding-right: 0px;
}
}