@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}

.privacypolicy-main-container{
    background-color: #ffffffc0 !important;
}

.privacypolicy-main-container h1{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
}

.privacypolicy-main-container h2{
    color: #fb6700;;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: bolder;
}

.privacypolicy-main-container p{
    margin-top: 0;
    margin-bottom: .9rem;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #7a7a7a;;
}

.privacypolicy-main-container li{
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #7a7a7a;;
}



/* Mobile */
@media only screen and (max-width: 990px) {
    .privacypolicy-main-container {
        padding:10px!important;
        padding-top:40px!important;
    }
    .privacypolicy-main-container p{
        font-size: small;
    }
   
}