.BasePageComponent{
    width: 100%!important;
    margin: 0px;
    padding: 0px;

    .menu-bar-button-icon{
        display: none;
    }
    @media only screen and (max-width: 990px) {
        // width: 103%!important;
        .menu-bar-button-icon{
            display:initial;
            margin-left:5px;
            margin-top:10px;
            position: absolute;
            left: 5px;
            z-index: 9;
        }
    }
}
.MuiPaper-root{
    z-index: 999999!important;
}
.MuiList-root{
    margin-top:-60px!important;
}