@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}

.aboutus-main-container{
    background-color: #ffffffc0 !important;
    padding-right: 30em;    
    position: relative;
}
.aboutus-main-container h1{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2.5rem;
}
.aboutus-sub-header-container{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.aboutus-main-container h2{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    font-size: 2rem;
    display: inline-block;
    margin-bottom: 0px;
    margin-right: 60px;
}
.aboutus-sub-header-divider{
    min-width: 0 !important;
    color: #FB6700 !important;
    border-top: 2px solid #FB6700 !important;
    display: inline-block;
    margin-right: 0px;
    margin-left: auto;
}
.aboutus-main-container h3{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.75rem;
}
.aboutus-main-container p{
    color: #7A7A7A;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.aboutus-main-container>div:first-child{
    position: absolute;
    top: 5em;
    right: 20px;
}
.aboutus-main-container .ant-anchor-ink-ball{
    border: 2px solid #FB6700;
}
.aboutus-main-container .ant-anchor-link-active > .ant-anchor-link-title {
    color: #FB6700;
}
.aboutus-main-container .ant-anchor-link-title:hover{
    color: #FB6700;
}
.aboutus-main-container .ant-anchor-link-title{
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 400;
    font-family: "ProximaNova", Sans-serif;
}
.aboutus-main-header-divider{
    min-width: 0 !important;
    width: 20%;
    color: #FB6700 !important;
    border-top: 3px solid #FB6700 !important;
}

.aboutus-main-container .ant-carousel img{
    width: 100%;
}
.aboutus-main-container .ant-carousel .slick-prev,
.aboutus-main-container .ant-carousel .slick-next {
    color: unset;
    font-size: unset;
}
.aboutus-main-container .ant-carousel .slick-dots-bottom{
    bottom: 55px;
}
.aboutus-our-team-carousel-desc{
    text-align: center;
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 1em;
}
.aboutus-school-partner-region-text{
    color: #FB6700;
    font-family: "ProximaNova", Sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.aboutus-school-partner-text{
    margin-top: 30px;
    padding-left: 30px;
}
.aboutus-school-partner-text>div{
    color: #7A7A7A;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.top-links-desktop{
    visibility: visible;
}
.top-links-mobile{
    visibility:hidden;
    padding-left:0px;
    padding-right:0px;
    margin-top:10px;
}

.top-links-mobile-row1 > a,.top-links-mobile-row2 > a{
    padding:10px!important; 
    color:grey!important;
}
.top-links-mobile-row1{
    margin-left:40px;
}
.top-links-mobile-row2{
    margin-left:10px;
    margin-bottom:30px;
}


/* Mobile */
@media only screen and (max-width: 990px) {
    .aboutus-main-container{
        padding-right: 12px;
    }
    .aboutus-sub-header-divider{
        display:none!important;
    }
    .top-links-desktop{
        visibility: hidden;
    }
    .top-links-mobile{
        visibility:visible;
    }
    .aboutus-overview{
        padding:20px!important;
    }
    #aboutus-our-partners,#aboutus-our-partners-social-community-partners{
        margin-top:15px;
        margin-bottom:15px;
    }
    .about-us-body{
        font-size: small!important;
    }
    h1{
        font-size: large!important;
    }
    h2,h3{
        font-size: medium!important;
    }
    .aboutus-school-partner-region-text{
        font-size: large!important;
    }
    .aboutus-school-partner-text > div{
        font-size: small!important;
    }
    .ant-divider{
        margin: 10px!important;
        margin-left:30px!important;
        margin-bottom:30px!important;
    }
    .ant-affix{
        display: none;
    }
}
