.Questionnaire{
    padding:120px!important;
    .module-header{
        font-weight: bold;
    }
    // .sub-header{
    //     display: inline-block;
    //     font-size: 18px!important;
    //     font-weight: bold!important;
    //     width:80px;
    // }
    // .sub-header-text{
    //     display: inline-block;
    //     font-size: 18px!important;
    //     margin-left:10px;
    // }
    // .welcome-header{
    //     font-size: 18px!important;
    //     margin-bottom: 15px;
    // }
    @media only screen and (max-width: 990px) {
        // padding: 50px!important;
        padding: 30px!important;
        zoom: 65%;
        padding-top: 80px!important;
    }
    


}