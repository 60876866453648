@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}
.contactus-subtitle{
    height: 60px;
    line-height: 60px;
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 30px;
}
.contactus-list-item{
    color: #7A7A7A;
    font-family: "ProximaNova", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 1px;
    margin: 10px 0px;
    
    display: flex;
}
.contactus-list-item svg{
    width: 16px !important;
    height: 20px;
    color: #FF7F50;
    font-size: 16px;
    margin-right: 16px;
    line-height: 20px;
    vertical-align: middle;
}
.contactus-email-list-item{
    color: #7A7A7A;
    font-family: "ProximaNova", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 1px;
    margin: 10px 0px;
}
.contactus-email-list-item a{
    color: #c36;;
}
.contactus-form-title{
    height: 60px;
    line-height: 60px;
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}
.contactus-main-container .ant-form-item-label label{
    height: 30px !important;
    line-height: 30px !important;
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 10px !important;
}

.contactus-main-container .ant-form-item-control-input-content .ant-select-selector,
.contactus-main-container .ant-form-item-control-input-content .ant-input{
    background-color: rgba(0,0,0,0.07) !important;
    border-radius: 15px;
}
.contactus-main-container .ant-form-item-control-input-content button{
    width: 100%;
    height: 50px;
    background-color: #FF7F50;
    color: #ffffff;
    border-color: #cc9955;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-radius: 24px 24px 24px 24px;
    text-align: center;
}
.contactus-form-title-divider{
    min-width: 0 !important;
    width: 10%;
    color: #FB6700 !important;
    border-top: 3px solid #FB6700 !important;
}



.contact-us-send-us{
    padding-left: "60px"
}

/* Mobile */
@media only screen and (max-width: 990px) {
    .contact-us-send-us{
        padding: 30px!important;
        padding-left:15px!important;
    }   
}