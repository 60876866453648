@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
    /* padding-top: 50px!important; */
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}


.section-main-content-title{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
}
.button-join-event{
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 0px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #FB6700;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #ffffff;
    border-radius: 7px 7px 7px 7px;
    padding: 17px 50px 17px 50px;
}
.col-center-content{
    display: flex;
    align-items: center;
    justify-content: center;    
}

.section-main-content-image{
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    vertical-align: middle;
}

.section-main-content-info{
    padding: 30px 0px 30px 40px;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.65);
    font-size: 17px;
}
.section-main-content-info p{
    text-align: left;
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    /* font-size: 0.9rem; */
    font-size: 1em;
    font-weight: 300;
    line-height: 1.4em;
    letter-spacing: 1px;
}
.section-main-content-info .info-header-text{
    color: #282828;
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2em;
    margin-bottom: 25px;
    margin-top: 40px;
}
.info-list-container{
    padding-left: 15px;
}
.info-list-container>div>div{
    margin-bottom: 15px;
}
.info-list-container .info-list-item-title{
    color: #FB6700;
    font-size: 17px;
    width: 50%;
    float: left;    
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    letter-spacing: 1px; 
}
.info-list-container .info-list-item-title svg{
    margin-right: 20px;
    width: 1em;
}
.info-list-container .info-list-item-text{
    color: #000000;
    font-size: 17px;
    width: 50%;
    float: left;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
}

.section-document-container table{
    background-color: transparent;
    width: 85%;
    margin-bottom: 15px;
    font-size: .9em;
    border-spacing: 0;
    border-collapse: collapse; 
    margin-left: 110px;
}
.section-document-container table td{
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
}
.section-document-container table td a{
    text-decoration: none;
    color: #c36!important;
}
.section-document-title{
}
.section-document-title.icon{
    width: 70px;
    height: 70px;
    background: #FFE6D5;
    padding: 20px;
    color: #FB6700;
    vertical-align: middle;
}
.section-document-title.text{
    color: #FB6700;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 700;
    line-height: 70px;
    font-size: 24px;
    padding-left: 40px;
    display: inline-block;
}

.section-center-header-text{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.section-organizedby-container>.row>div{
    display: flex;
    align-items: center;
    justify-content: center;    
}

.section-testimonial-card-container{
    padding-bottom: 80px;

}

.section-testimonial-card-container-text{
    font-family: "ProximaNova", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5;
    color: #7a7a7a;
    margin-bottom: 30px;
    margin-top: 30px;
}

.section-testimonial-card-container img{
    width: 50px !important;
    height: 50px;
    border-radius: 999px;
    object-fit: cover;
}
.section-testimonial-card-container-name{
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    color: #7a7a7a;
}
.section-testimonial-card-container-title{
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    color: #6ec1e4;
}


.event-activity-join-event-modal{
    font-family: "ProximaNova", Sans-serif;
}
.event-activity-join-event-modal .ant-modal-header{
    background: #EA2325;
}
.event-activity-join-event-modal .ant-modal-header .ant-modal-title{
    color: #FFFFFF;
}

.event-activity-join-event-modal .ant-radio-button-wrapper-checked{
    background: #EA2325 !important;
    border-color: #EA2325 !important;
}

.event-activity-join-event-modal .event-activity-group{
    margin-bottom: 50px;
}

.event-activity-join-event-modal .event-activity-group .event-activity-group-title{
    font-size: 24px;
    font-weight: 600;
}
.event-activity-join-event-modal .event-activity-section{
    margin-left: 30px;
    margin-bottom: 20px;
}

.event-activity-join-event-modal .event-activity-section .event-activity-name{
    font-size: 16px;
    font-weight: 400;
}

.event-activity-join-event-modal .event-activity-section .event-activity-date{
    font-size: 18px;
    font-weight: 600;
}
.event-activity-join-event-modal .event-activity-section .event-activity-date svg{
    margin-right: 20px;
}

.event-detail-header-container{
    margin-top:120px!important;
}
.alert{
    margin-top: 90px;
    padding-right: 60px;
}
.modal-dialog .alert{
    margin-top: 0px;
    padding-right: 20px;
}


/* Mobile */
@media only screen and (max-width: 1025px) {
.section-document-container table{
    width: 100%;
    margin-left: 0px;
}
.section-main-content-info{
    padding: 30px 10px 30px 10px;
}

.info-list-container>div>div{
    margin-bottom: 5px;
}
.info-list-container .info-list-item-title{
    width: 100%; 
}
.info-list-container .info-list-item-text{
    width: 100%;
    padding-left: 35px;
    margin-bottom: 25px;
}
.section-main-content-title{
    text-align: center;
}
}

@media only screen and (max-width: 990px) {
    .event-detail-header-container{
        margin-top:initial!important;
        padding-top:70px!important;
    }
    .section-main-content-title{
        font-size: large!important;
        margin-bottom: 10px!important;
    }
    .button-join-event{
        font-size: 12px!important;
        padding: 10px 20px 10px 20px!important
    }
    .section-main-content-image{
        margin-top:10px!important;
        margin-left:0px!important;
    }
}