.Section10{
    .section-impact-container{
        background: #f7f7f7;
        height:103%;
        padding-top:1%;
        margin-bottom:20px;
    }
    .section-impact-col1{
        padding-top:10%!important;
    }
    .section-impact-header{
        color: #000000;
        font-weight: 900;
        line-height: 1em;
        font-size:2rem;
        margin-left:5%;
        margin-bottom:3%;
    }
    .section-impact-content{
        color: grey!important;
        line-height: 25px;
        margin-left:5%;
    }
    .section-impact-boxes{
        width:45%!important;
        height:200px;
        background: white;
        text-align: center;
        padding-top:30px;
        margin-right:10px;
    }
    .section-impact-boxes-text1{
        color: #FF6D31;
        font-size: 35px;
        font-weight: 800;
        margin-top:10px;
    }
    .section-impact-boxes-text2{
        color: #767676;
        font-family: "ProximaNova", Sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .section-impact-icons{
        font-size: 50px;
        color:grey;
    }
    @media only screen and (max-width: 990px) {
        .section-impact-boxes{
            width:100%!important;
        }
    }
}