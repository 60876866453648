.drop-down {
    list-style: none;
  }
  
.drop-down li {
  padding: 10px 0px;
  border-bottom: 1px solid #cccccc;
  width: 150px;
  text-align: center;
  background: #fbf6f6;
}

.dx-dialog{
  z-index: 999999999!important;
}