.Section6{
    padding:10px!important;
    .section-container{
        margin-top:3%;
        //margin-bottom: 100px;
        text-align: center;
    }
    .section-img{
        width:378px;
        height:252px;
        object-fit: contain;
    }
    .section-div-header-6{
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 600;
        font-size: 59px;
        text-align: left;
        margin-left: 1%;
        /* margin-top:130px; */
    }
    .carousel-events{
        margin-left:5%;
        margin-top:3%;
        padding:10px;
    }
    .section-card-container{
        border: 0px solid lightgrey;
        width: 97%;
        background: white;
    }
    $section-card-container-text-height:450px;
    .section-card-container-text{
        height: $section-card-container-text-height;
        overflow: hidden
    }
    $section-card-container-text-height:450px;
    
        
    .section-card-container-header{
        font-size: 1.5rem;
        text-shadow: none;
        font-size: 24px;
        text-transform:none;
        margin-top:5px;
        color:black;
        text-align: left;
        margin:10px;
        font-weight: 600;
        //height: 100px;
    }
    .section-card-container-by{
        font-size: 1.5rem;
        text-shadow: none;
        font-size: 18px;
        text-transform:none;
        margin-top:5px;
        color:black;
        text-align: left;
        margin:10px;
        font-style: italic;
        font-weight: 400;
        margin-top:15px;
    }
    .section-card-container-content{
        font-size: 1.5rem;
        text-shadow: none;
        font-size: 17px;
        text-transform:none;
        margin-top:5px;
        color:grey;
        text-align: left;
        margin:10px;
        margin-top:15px;
        font-weight: 400;
        height: 100%;
        overflow: hidden;
        padding: 5px;
        max-height: 310px;
    }
    .section-card-container-readmore{
        font-size: 1.5rem;
        text-shadow: none;
        font-size: 18px;
        text-transform:none;
        margin-top:5px;
        color:#ff8450;
        text-align: left;
        margin:10px;
        margin-top:20px;
        font-weight: 200;
        text-decoration: underline;
    }
    .button-orange-joinnow{
        text-shadow: none;
        padding: 7px 12px 7px 12px;
        font-family: "ProximaNova", Sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4em;
        letter-spacing: 1px;
        border-width: 0px;
        border-radius: 5px;
        background-color: #ff8450;
        border-color: #0B6091;
        color: #fff;
        display: inline-block;
        position: relative;
        bottom: 15px;
        right: -30%;
        text-align: right;
    }
  
   .show-more-content{
        text-align: justify;
        font-size: 17px;
        color: grey;
        // height: 100%;
        overflow: hidden;
        padding: 0px;
   }
   .section-card-container-text div span{
       text-align: left;
   }
    
    @media only screen and (max-width: 990px) {
        .section-div-header-6 {
            position: relative;
            font-size: 40px;
        }
        .carousel-events {
            margin-left: 0;
            margin-top: 0;
            padding: 0;
        }
        .section-card-container-header {
            font-size: 22px;
        }
    }
}