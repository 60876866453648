.ServicesList {
    padding-top: 110px!important;
    
    h2{
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-size: 25px;
        font-weight: 600;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
        margin-top:30px;
    }
    .menu-item{
        margin-left:5px!important;
        padding:5px;
        cursor:pointer;
    }
    @media only screen and (max-width: 990px) {
        padding-top: 70px!important;
        margin-left:20px!important;
    }
}