.overFlowHidden{
    overflow: hidden!important;
}


@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

.container-nopad-nomargin{
    padding: 0px!important;
    margin: 0px!important;
    width: 100%!important;
    max-width: 100%!important;
}

.button-menu-burger{
    background: transparent;
    border: 0px;
    color: red;
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 9999;
    font-size: x-large;
}

.MuiPaper-root{
    background: #54595f!important;
    color: white!important;
    width: 350px!important;
}
.MuiListItemText-primary{
    cursor: pointer;
}
.MuiList-padding{
    padding-top:80px!important;
}
.indent-menu{
    padding-left:20px;
}

.button-red-signup-menu{
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color:  #bd2944;
    border-color: #0B6091;
    color:white;
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 150px;
}
.button-yellow-login-menu{
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color: #FFCC00;
    border-color: #0B6091;
    color:  black;
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 20px;
}
.button-yellow-login-menu svg{
    color:black;
    margin-left:5px;
    font-weight: 300;
}
.ydmslogo-menu{
    width:20%;
    position: absolute;
    top: 3%;
    left: 10%;
    z-index: 9999;
}

.modal{
    z-index: 99999!important;
}

.modal-header{
    background: #ea2325!important;
    color: white!important;
}

.ModalCookie{
    .modal-header{
        background: initial!important;
        color: initial!important;
    }
}

.eye-button {
    background-color: transparent;
    border: transparent;
}

.button-red-signup-mobile {
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color: #ed2325;
    border-color: #0b6091;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: 4%;
    right: 110px;
    z-index: 9999;
    // margin-right: 10px;
  }
  .button-yellow-login-mobile {
    text-shadow: none;
    padding: 7px 20px 7px 20px;
    font-family: "ProximaNova", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 1px;
    border-width: 0px;
    border-radius: 5px;
    background-color: #ffcc00;
    border-color: #0b6091;
    color: black;
    display: inline-block;
    position: absolute;
    top: 4%;
    right: 10px;
    z-index: 9999;
    margin-right:0px!important;
  }

@media only screen and (max-width: 1025px) {
    .otherpagebody{
        padding-top:0px!important;
        padding-left:5px!important;
        padding-right:15px!important;
    }
    .menu-bar-button-icon{
        background-color: #ed2325!important;
        border-color: transparent;
        opacity:1!important;
    }

    .button-red-signup-mobile {
        text-shadow: none;
        padding: 7px 20px 7px 20px;
        font-family: "ProximaNova", Sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.4em;
        letter-spacing: 1px;
        border-width: 0px;
        border-radius: 5px;
        background-color: #ed2325;
        border-color: #0b6091;
        color: #fff;
        display: inline-block;
        position: absolute;
        top: 4%;
        right: 90px;
        z-index: 9999;
        // margin-right: 62px;
      }
      .button-yellow-login-mobile {
        text-shadow: none;
        padding: 7px 20px 7px 20px;
        font-family: "ProximaNova", Sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.4em;
        letter-spacing: 1px;
        border-width: 0px;
        border-radius: 5px;
        background-color: #ffcc00;
        border-color: #0b6091;
        color: black;
        display: inline-block;
        position: absolute;
        top: 4%;
        right: 0px;
        z-index: 9999;
        margin-right:5px!important;
      }
      .container {
        padding:initial!important;
      }
      .service-section-main-content-title{
          margin-top:60px!important;
          font-size: 23px!important;
          padding-left:10px;
          padding-right:10px;
      }
      .service-page-sub-header{
          font-size: large!important;
      }
}