.Section9{
    .section-container{
        display:none;
        margin-top:3%;
        margin-bottom: 100px;
        text-align: center;
    }
    .section-img{
        width:90%;
    }
    .section-div-header-6{
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 600;
        font-size: 59px;
        text-align: left;
        margin-left: 5%;
        /* margin-top:130px; */
    }
    .carousel-events{
        margin-left:5%;
        margin-top:3%;
        padding:10px;
    }
    .section-gallery-container{
        margin-right:20px;
    }
    .section-gallery-text{
        font-size: 1.5rem;
        text-shadow: none;
        font-size: 18px;
        text-transform:none;
        margin-top:5px;
        color:black;
        margin:10px;
        margin-top:10px;
        font-weight: 400;
    }
}