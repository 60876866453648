@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}
.newspage-post-card{
    width: 340px;
    /* height: 448px; */
    background-color: #fff;
    overflow: hidden;
}
.newspage-post-card img{
    width: 100%;
    height: 230px;
}
.newspage-post-card-content{
    padding: 10px 30px;
}
.newspage-post-card-content h3,.newspage-post-card-content p{
    margin: 0px;
    line-height: 0.8;
}
.newspage-post-card-content h3 a,
.newspage-newslist-card h3 a{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    font-size: 21px;
}
.newspage-post-card-content p{
    color: #7A7A7A;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    line-height: 1.5em;
    font-size: 14px;
    margin: 15px 0;
}
.newspage-post-card-content>a{
    color: #FB6700;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "ProximaNova", Sans-serif;
}
.newspage-post-card-bottom{
    padding: 5px 30px;
}
.newspage-post-card-bottom div{
    height: 30px;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    line-height: 30px;
    font-size: 12px;
    color: #adadad;
}
.newspage-header{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 40px 0;
    line-height: 1;
    font-size: 2.5rem;
}

.newspage-newslist-card{
    background-color: #fff;
    width: 320px;
}
.newspage-newslist-card h3{
    padding: 5px 30px;
}
.newspage-card-box-shadow{
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    border-radius: 3px;
    border: 0 solid #818a91;
}
.newspage-container .ant-menu .ant-menu-item{
    background-color: #FFFDF9;
    font-size: 16px;
    font-family: "ProximaNova", Sans-serif;
}
.newspage-container .ant-menu .ant-menu-item:hover{
    color: #983700;
    background-color: #FFD4AE;
}
.newspage-container .ant-menu .ant-menu-item-selected{
    background-color: #FFD4AE;
    color: #983700;
}
.newspage-container .ant-menu .ant-menu-item-selected::after{
    border-right: 3px solid #983700;
}
.newspage-container .ant-layout-sider-children ul.ant-menu{
    border: none;
}
.newspage-container .ant-menu .ant-menu-item a:hover,
.newspage-container .ant-menu-item-selected a,
.newspage-container .ant-menu-item-selected a:hover {
    color: #983700;
}



/* Mobile */
@media only screen and (max-width: 1025px) {
   
}