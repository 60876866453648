@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}
.newsdetail-header-container{
    text-align: center;
    font-family: "ProximaNova", Sans-serif;
}
.newsdetail-header-container h1{
    color: #0c0c0c;    
    font-size: 55px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 20px;
}
.newsdetail-header-container .newsdetail-header-info{
    height: 25px;
    line-height: 25px;
    color: #FB6700;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 45px;

}
.newsdetail-header-container .newsdetail-header-info svg{
    margin-right: 8px;  
}
.newsdetail-header-container .newsdetail-header-info span{
    padding: 0 8px;
}

.newsdetail-content-container{
    font-family: "ProximaNova", Sans-serif;
}
.newsdetail-content{
    color: #000000;
    font-size: 20px;
    font-weight: 400;
}
.newsdetail-content img{
    width: 100%;
}

.newsdetail-social-media-share{
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}
.newsdetail-social-media-share>div:first-child{
    color: #0c0c0c;
    font-size: 22px;
    font-weight: 700;
}
.newsdetail-share-button-list{
    width: 70%;
    display: flex;
    justify-content: space-between;

}
.newsdetail-share-button{
    height: 100%;
    color: #fff;
    padding: 0 16px;
}
.newsdetail-share-button:hover{
    filter: brightness(120%);
}
.newsdetail-share-button a{
    display: block;    
    color: #fff;
}

.newsdetail-share-button svg{
    margin-right:16px;
    font-size: 20px;
    vertical-align: middle;
}

.newsdetail-suggest-news-list{
    position: sticky;
    top: 120px;
}

.newsdetail-suggest-news-list>div:first-child{
    color: #6C7C69;
    font-family: "ProximaNova", Sans-serif;
    font-size: 40px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 44px;
    letter-spacing: -1.13px;
    margin-bottom: 20px;
}

.newsdetail-suggest-news-list-item{
    width: 100%;
    height: 95px;
    display: flex;
    margin-bottom: 20px;
}
.newsdetail-suggest-news-list-item img{
    width: 146px;
    height: 100%;
    margin-right: 20px;
}
.newsdetail-suggest-news-list-item-text a{
    color: #6C7C69;
    font-size: 24px;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
    line-height: 25px;
    letter-spacing: -0.68px;
}
.newsdetail-suggest-news-list-item-text p{
    color: #6C7C69;
    font-size: 14px;
    font-weight: 300;
}




/* 150% */
@media only screen and (max-width: 1300px) {

.newsdetail-suggest-news-list-item-text a{
    font-size: 17px;
}
.newsdetail-suggest-news-list-item-text p{
    font-size: 12px;
}

.newsdetail-share-button-list{
    width: 80%;

}

}


/* mobile */
@media only screen and (max-width: 1024px) {

.newsdetail-header-container img{
    width: 80vw;
}
    
.newsdetail-header-container h1{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 15px;
}
.newsdetail-header-container .newsdetail-header-info{
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
}



.newsdetail-social-media-share{
    display: block;
    height: 150px;
}
.newsdetail-share-button-list{
    width: 100%;
}
.newsdetail-share-button{
    height: 100%;
    color: #fff;
    padding: 0 12px;
}

.newsdetail-share-button a{
    display: block;    
    color: #fff;
}

.newsdetail-share-button svg{
    margin-right:16px;
    font-size: 20px;
    vertical-align: middle;
}

.newsdetail-suggest-news-list-item-text a{
    font-size: 12px;
}
.newsdetail-suggest-news-list-item-text p{
    font-size: 8px;
}
    
}

@media only screen and (max-width: 990px) {
    .newsdetail-header-container{
        padding-top: 70px!important;
    }
    .newsdetail-content{
        padding:10px!important;
    }
    .newsdetail-content  img{
        width:100%!important;
        height:initial!important;
    }
    .newsdetail-content div{
        font-size: smaller;
    }
    
}

