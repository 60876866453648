@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}	



.section-standard-header{
    background-attachment: fixed;
    background-image: linear-gradient( rgba(255, 255, 255, 0.9) , rgba(255, 255, 255, 0.9) ),url("https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/737a1ed309968edcba752228f022ec7.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding-top: 110px;
    padding-bottom: 20px;
}

.section-standard-header-content{
    margin-bottom: 12px;
}
.section-standard-header-content.text1{
    color: #FF5E22;
    font-family: "ProximaNova", Sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.section-standard-header-content.text2{
    color: rgba(0,0,0,0.7);
    font-family: "ProximaNova", Sans-serif;
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.1666em;
}
.section-standard-header-content.text3{
    color: rgba(0,0,0,0.54);
    font-family: "ProximaNova", Sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.617em;
}
.section-standard-header-content.social-media-grid{
    display: inline-block;
    width: 100%;
    word-spacing: 10px;
}
.section-standard-header-social-media-icon{
    color: #ff7f50;
    background: #fff;
    position: relative;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 40px !important;
    height: 40px;
    text-decoration: none;
    border-radius: 100%;    
    text-align: center;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}


/* Mobile */
@media only screen and (max-width: 1025px) {
.section-standard-header{
    padding-top: 70px;
    padding-bottom: 10px;
}

.section-standard-header-content{
    margin-bottom: 12px;
}
.section-standard-header-content.text1{
    font-size: 0.65em; 
}

.section-standard-header-content.text2{
    font-size: 2em;
    line-height: 1em;
}
.section-standard-header-content.text3{
    font-size: 0.9em;
    line-height: 1em;
}
}