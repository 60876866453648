@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 99%!important;
        max-width: 99%!important;
}

.faq-main-container{
    background-color: #ffffffc0 !important;
}
.faq-main-container>div:first-child{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-size: 39px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 1.2em;
    padding-top: 1em;
}
.faq-main-container>p{
    text-align: center;
    color: #7A7A7A;
    font-size: 1.4em;
    font-weight: normal;
    text-transform: none;
    font-style: italic;
    text-decoration: none;
    line-height: 1.3em;
    letter-spacing: 0px;
    color: #7a7a7a;
    font-family: "ProximaNova", Sans-serif;
    margin-top: 2.5em;
    padding-bottom: 2em;
}
.faq-main-container>p a{
   color: #FB6700 !important;
}

.faq-collapse > .ant-collapse-item > .ant-collapse-header {
    font-family: "ProximaNova", Sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: #545454;
}
.faq-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded=true]{
    color: #FB6700;
}
.faq-collapse svg{
    color: #FB6700;
    font-size: 0.8em !important;
    margin-right: 1.5em !important;
}
.faq-collapse p{
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    color: #7a7a7a;
    font-size: 1.15em;
}

.faq-collapse .ant-collapse-item{
    border-bottom: 1px solid #FFBF96 !important;
}


/* Mobile */
@media only screen and (max-width: 1025px) {
.faq-main-container>div:first-child{
    font-size: 20px;
}
}