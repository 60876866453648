.Section5{
    margin-top:20px;
    margin-bottom:20px;
    .section-div-text-1{
        position:relative;
        width:100%;
        height:100%;
        /* height:100%; */
        background: #e8ffe7;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
    }
    .section-div-image{
        width:100%;
        background: transparent;
        display: inline-block;
        /* height:444px; */
        vertical-align: top;
    }
    .section-div-image img{
        width:100%;
    }
    .section-img{
        width:60%;
    }
    .section-div-header{
        position:absolute;
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 600;
        // font-size: 2.5vw;
        font-size: 39px;
        position:relative;
        margin-top:4%;
        text-align: left;
        left:8%;
        // border: 1px solid grey;
    }
    .section-div-content{
        // font-size: 1.2vw;
        font-size: 15px;
        color: #7A7A7A;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 400;
        text-align: left;
        position:relative;
        margin-top:3%;
        left:10%;
        width:80%;
        height:45%;
        overflow: hidden;
        // border: 1px solid grey;
        // line-height: 25px;
        /* margin-top:10px; */
    }
    .section-div-carousel{
        position:absolute;
        margin-bottom:5px;
        bottom:3px;
        // left:10%;
        // left:10%;
        // margin-top:3%;
        // margin-bottom: 4%;
        // border: 1px solid grey;
    }
    .section-div-text1,.section-div-text2{
        font-family: "ProximaNova", Sans-serif;
        font-weight: 200;
        color:#7A7A7A;
        text-shadow: none;
        font-size: 12px;
        text-transform: capitalize;
        margin-top:5px;
        line-height: 15px;
        text-align: center;
    }

    .col-lg-6{
        display:flex;
    }

    @media only screen and (max-width: 990px) {
        .section-div-header,
        .section-div-content,
        .section-div-carousel{
            position:relative;
        }
        .col-12{
            display:inline-block!important;
        }
        .section-div-carousel{
            display: inherit;
            margin-bottom:15px;
        }
        .section-div-header{          
            font-size: 30px;
        }
       
    }
    .react-multi-carousel-track {
        text-align: center;
    }
}

