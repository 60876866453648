@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 700;
    src: url(../../src/assets/fonts/ProximaNova-Bold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 300;
    src: url(../../src/assets/fonts/ProximaNova-Light.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 400;
    src: url(../../src/assets/fonts/ProximaNova-Regular.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 600;
    src: url(../../src/assets/fonts/ProximaNova-Semibold.otf) format('OpenType');} 
@font-face {font-family: "ProximaNova";font-display: auto;font-weight: 800;
    src: url(../../src/assets/fonts/ProximaNova-Extrabold.otf) format('OpenType');}			

 
.otherpagebody {
    background: url("../assets/images/bg.png") repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 65% auto;
}

.container-nopad-nomargin{
        padding: 0px!important;
        margin: 0px!important;
        width: 100%!important;
        max-width: 99%!important;
}

.event-filter-search-form .ant-form{
    height: 55px;
    background-color: #DADADA;
    padding: 0 8%;
    display: flex;
    justify-content: right;
    align-items: center;
}


.event-filter-search-form .ant-form-item-control-input-content .ant-input,
.event-filter-search-form .ant-form-item-control-input-content .ant-picker,
.event-filter-search-form .ant-form-item-control-input-content .ant-btn-primary{
    border-radius: 5px;
}

.event-filter-search-form .ant-form-item-control-input-content .ant-input:hover,
.event-filter-search-form .ant-form-item-control-input-content .ant-picker:hover,
.event-filter-search-form .ant-form-item-control-input-content .ant-input:focus,
.event-filter-search-form .ant-form-item-control-input-content .ant-picker:focus
{
    border: 1px solid #8e949b;
}

.section-div-event-title-text{
    color: #000000;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 1;
}


.section-div-image-list-row{
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    border-radius: 5px;
    height: 357px;
}
.section-div-image-list-col{
    height: 100%;
}

.section-div-image-list-row:hover{
    box-shadow: none;
}

.section-div-image-list{
    width:100%;
    height: 100%;
    background: transparent;
    opacity: 1;
    transition-duration: 0.5s;
    display: inline-block;
    vertical-align: middle;
}
.section-div-image-list img{
   width:100%;
   height: 100%;
   object-fit: contain;
   border-radius: 5px 0px 0px 5px;
}
.section-div-text-list{
    width:100%;
    height:100%; 
    /* background: #FF0000; */
    display: inline-block;
    padding: 1em 2em 1em 2.7em;
    vertical-align: top;
    border-radius: 0px 5px 5px 0px;
    overflow: auto;
}

.section-div-header-list{
    color: #ffffff;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 700;
    font-size: 18px;
    position:relative;
    top:10px;
    text-align: left;
}
.section-div-content-list{
    font-size: 16px;
    height: 125px;
    color: #ffffffde;
    font-family: "ProximaNova", Sans-serif;
    font-weight: 400;
    text-align: left;
    position:relative;
    padding-top:25px;
    width:80%;
    line-height: 25px;
    overflow: hidden;
    /* margin-top:10px; */
}
.section-div-content-list-button-container{
    padding: 10px 0px 5px 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: rgba(255,255,255,0.32);
    display: inline-block;    
}
.section-div-content-list-button{
    font-family: "ProximaNova", Sans-serif;
    font-size: 0.82em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    fill: #ffffff;
    color: #ffffff;
    text-decoration: none;
    background-color: rgba(255,255,255,0);
    border-radius: 5px 5px 5px 5px;
    padding: 0em 0em 0.3em 0em;  
    transition: font-size .2s ease-in-out;  
}
.section-div-content-list-button:hover{
    /* transform: scale(1.5);   */
    font-size: 0.95em;
    color: #fff;
}

.section-event-category-container{
    width:800px;
    background-color: #FFFDF9;
    box-shadow: 0px 0px 1.5px 0px rgb(168 53 8 / 50%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 25px 25px 25px 25px;
    max-width: 1140px;
}
.section-event-category-col{
    /* padding: 25px 12px 25px 12px; */
    padding: 5px 4px 5px 4px;
    color: #414141;
    display: flex;
    align-items: center;
}
.section-event-category-col a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #414141;
    padding: 20px 8px 20px 8px;
    border-radius: 20px;
}
.section-event-category-col a:hover, .section-event-category-col a:active{
    color: #983700;
    background-color: #FFD4AE;
}
.section-event-category-icon{
    font-size: 15px;
}
.section-event-category-text{
    font-family: "ProximaNova", Sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 15px;
}


.event-category-container .ant-tabs-nav{
    padding: 0 0 0 12px;
    background-color: #FFFDF9;
    border: 1px solid #ecd4c6;
    border-radius: 20px;
    box-shadow: 0px 0px 1.5px 0px rgb(168 53 8 / 50%) !important;
   /* // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s !important;  */
}
.event-category-container .ant-tabs-tab{
    width: 130px;
    height: 75px;
}
.event-category-container .ant-tabs-tab + .ant-tabs-tab{
    margin: 0 0 0 25px;
} 
.event-category-container .ant-tabs-tab .ant-tabs-tab-btn{
    width: 130px;
    height: 60px;
    
}
.event-category-container .ant-tabs-ink-bar{
    background: #983700;
}
.event-category-tab-card{
    padding: 0 8px;    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
    white-space: normal;
}
.event-category-container .ant-tabs-tab-btn:hover,
.event-category-container .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #983700 !important;
    background-color: #FFD4AE !important;
    border-radius: 20px;
}
.event-category-icon{
    font-size: 16px;
    margin-right: 8px;
}
.event-category-text{
    font-family: "ProximaNova", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 15px;
}
.error-message{
    color: red;
    margin-left: 10px;
    font-size: small;
}











.col-no-gap{
    padding-left: 0px;
    padding-right: 0px;
}

.button-load-more-orange{
    font-family: "ProximaNova", Sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 0px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #FB6700;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #ffffff;
    border-radius: 7px 7px 7px 7px;
    padding: 17px 50px 17px 50px;
}
.col-center-content{
    display: flex;
    align-items: center;
    justify-content: center;    
}

.event-filter-search-form-mobile{
    display:none!important;
}

/* Mobile */
@media only screen and (max-width: 1025px) {
.section-event-category-container{
    width:350px;   
}

.section-div-image-list-row{
    margin-bottom: 50px;
    height: auto;
}
.section-div-image-list img{
    border-radius: 5px 5px 0px 0px;
}
.section-div-text-list{
    border-radius: 0px 0px 5px 5px;
}
.section-div-event-title-text{
    font-size: 1.5em;
}
.event-filter-search-form .ant-form{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    
}

.event-filter-search-form .ant-form-item-control-input-content .ant-input,
.event-filter-search-form .ant-form-item-control-input-content .ant-picker{
    width: 105px !important;
    height: 30px !important;
    
}
.event-filter-search-form .ant-form-item-control-input-content button{
    height: 30px !important;
    width: 30px !important;
}
.event-filter-search-form .ant-form-inline .ant-form-item{
    margin-right: 5px;
}

.event-filter-search-form .ant-form-item-control-input-content input,.event-filter-search-form .ant-form-item-control-input-content button{
    font-size: 12px !important;
}
}




/* 150% */
@media only screen and (max-width: 1300px) {

.section-div-header-list{
    font-size: 16px;
    position:relative;
    top:20px;
}
.section-div-content-list{
    font-size: 12px;
    height: 120px;
    position:relative;
    padding-top:25px;
    width:80%;
    line-height: 20px;
}
}

@media only screen and (max-width: 990px) {
    .event-filter-search-form,.section-div-event-title-text-container{
        display:none;
    }
    .event-category-container{
        margin-top: 5px;
    }
    .event-category-tabs > .ant-tabs-nav{
        zoom:60%!important;
        margin-bottom:0px!important;
        border:0px!important;
        box-shadow: 0px 0px 0px 0px transparent !important
    }
    .event-category-container .ant-tabs-tab-btn:hover, .event-category-container .ant-tabs-tab-active .ant-tabs-tab-btn{
        background-color: transparent!important;
    }
    .event-filter-search-form-mobile{
        display:block!important;
        margin-left:20px!important;
        margin-top:0px!important;
    }
    .event-filter-search-form-col{
        padding:0px!important;
        margin-right: 2px;
        margin-left: 2px;
    }
    .event-filter-search-form-row{
        margin-top:5px!important;
        /* margin-bottom:-20px!important; */
    }
}