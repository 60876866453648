.profileNavBar {
    .dropdown-item {
        text-shadow: none !important;
    }
}

.myTooltip {
    .tooltip-inner {
        background-color: #fff;
        color: #000;
        border: 1px solid #062e56;
        text-align: left;
    }

    .tooltip-arrow {
        color: #fff !important;
    }
}