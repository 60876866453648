.Footer{
    margin-top: 30px;
    // position: absolute;
    // width: 100%;
    // bottom: 0;

    .footer{
        background: #54595f;
        padding-left:5%!important;
        padding-bottom:2%!important;
        padding-top:1%;
    }
    .footer-column{
        margin-top:3%!important;   
    }
    .section-footer-icon-text {
        margin-top:10px;
    }
    .section-footer-icon-text svg{
        color: #FFCC00;
        margin-right:15px;
        font-size: 21px;
    }
    .section-footer-icon-text {
        color: rgb(202, 202, 202);
        font-size: 14px;
        font-weight: 400;
    }
    .section-footer-subheader{
        color:rgb(221, 221, 221);
        margin-bottom:5px;
    }
    .section-footer-links{
        color: rgb(175, 175, 175);
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
    }
    .section-footer-div-input{
        margin-top:10px;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(175, 175, 175);
        opacity: 1; /* Firefox */
    }
    
    .button-yellow-subscribe{
        text-shadow: none;
        padding: 10px 72px 10px 72px;
        font-family: "ProximaNova", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.4em;
        letter-spacing: 1px;
        border-width: 0px;
        border-radius: 5px;
        background-color: #FFCC00;
        border-color: #0B6091;
        color: #fff;
        display: inline-block;
        margin-top:10px;
        /* position: absolute;
        top: 370px;
        left: 150px; */
    }
    .news-letter-input{
        background-color: rgba(255,255,255,0.09);
        border-width: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
        width: 100%;
        max-width: 100%;
        border: 0px solid #818a91;
        background-color: transparent;
        flex-grow: 1;
        padding: .5rem 1rem;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        appearance: auto;
        background: #656a6e;
        width:250px;
    }
    .section-footer-div-input{
        margin-top:10px;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    
    @media only screen and (max-width: 990px) {
        // width:102%!important;
        .footer{
            padding-left:10%!important;
            // padding-bottom:2%!important;
            // padding-top:1%;
        }
    }

    .img-apple-download,.img-android-download{
        width:120px!important;
        margin-right:10px;
        margin-top:5px;
    }
}