.Section8{
    .section-container{
        margin-top:3%;
        margin-bottom: 100px;
        text-align: center;
    }
    .section-img{
        width:90%;
    }
    .section-div-header-6{
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 600;
        font-size: 59px;
        text-align: left;
        margin-left: 1%;
        /* margin-top:130px; */
    }
    .carousel-events{
        margin-left:5%;
        margin-top:3%;
        padding:10px;
    }
    .section-service-card{
        width: 90%;
    }
    .section-service-card img{
        width: 102px;
        height: 102px;

    }
    .section-service-card-header{
        color: #000000;
        font-family: "ProximaNova", Sans-serif;
        font-size: 22px;
        font-weight: 600;
    }
    .section-service-card-description{
        color: #7A7A7A;
        font-family: "ProximaNova", Sans-serif;
        font-weight: 400;
        font-size: 16px;
    }
    .section-service-card a{
        font-family: "ProximaNova", Sans-serif;
        font-size: 22px;
        fill: #FB6700;
        color: #FB6700!important;
    
    }

    @media only screen and (max-width: 990px) {
        .section-div-header-6{
            position:relative;
            font-size: 40px;
        }  
    }

}