.AttendanceTaking{
    padding:120px!important;
    .sub-header{
        display: inline-block;
        font-size: 18px!important;
        font-weight: bold!important;
        width:80px;
    }
    .sub-header-text{
        display: inline-block;
        font-size: 18px!important;
        margin-left:10px;
    }
    .welcome-header{
        font-size: 18px!important;
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 990px) {
        padding: 50px!important;
    }
    


}